<!--
#############################################################################################
######    Vista SVC11190  :  Ventana AUXILIAR con PROPIEDADES de un HUECO DEL ALMACÉN  ######
#############################################################################################
-->
<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="lp10Close()">
        <div class="modal-container" @click.stop="">
          <!-- Encabezado -->
          <div class="modal-header" v-show="lbResultadosCompletos">
            <div class="igs-left" style="display:flex; flex-direction:row;">
              <qr-code :text="'99'+ubicacion" size=56 error-level="H"></qr-code>&nbsp;&nbsp;
              <div>
                <span v-html="f93MaquetarUbicacion(ubicacion,1)"></span>
                <!-- Tipo de Ubicación -->
                <div class="igs-fs-12">
                    <span class="fa fa-edit" aria-hidden="true" @click="lp60ConmutarEdicionTipoUbicacion()">&nbsp;</span>
                    &nbsp;&nbsp;
                    <select v-show="lbEditarTipoUbicacion" v-model="r1mpa.m011" class="igs-fs-9 igs-left">
                      <option value="0">0-Dinámico Estantería</option>
                      <option value="1">1-Silo Dinámico (MonoProducto)</option>
                      <option value="3">3-Estático (Producto Fijo)</option>
                      <option value="4">4-Manual Estantería (No sugerible por SGA)</option>
                      <option value="5">5-Cuarentena/Contaminado</option>
                      <option value="6">6-Silo Dinámico (MultiProducto)</option>
                    </select>
                    &nbsp;&nbsp;
                    <span v-show="lbEditarTipoUbicacion" class="fa fa-save" aria-hidden="true" @click="lp60GrabarCambioTipoUbicacion()">&nbsp;&nbsp;</span>
                </div>
              </div>
            </div>
            <div class="igs-right">
              <span class="igs-l2"><code>{{ ubicacion }}&nbsp;</code></span>
                <span v-html="f93MaquetarDCUbicacion(ubicacion,1)"/>&nbsp;
                <span class="fa fa-window-close" aria-hidden="true" @click="lp10Close()"></span>
              </div>
          </div>

          <!-- Cuerpo -->
          <div class="modal-body" v-show="!lbResultadosCompletos">
            <div v-html="ltDetallesHueco"></div>
          </div>

          <div class="modal-body" v-show="lbResultadosCompletos">
            <div class="row">
                <div :class="lf91ClaseImagen">
                  <img v-if="(this.r1mpa.m022 != '')" :src="this.lf91ImagenArticulo500(r1mpa.m022)" style="max-height:180px;max-width:180px;height:auto;width:auto;">
                  <br/><br/>
                  <barcode :value="r1mpa.a051" height="16" width="1" marginTop=1 marginBottom=1 displayValue="true" fontSize="12">{{r1mpa.a051}}</barcode>
                  <br/>
                  <div class="row" v-if="r1mpa.m011 != '6'">
                    <div class="col-md-1"><span class="fa fa-route"></span></div>
                    <div class="col-md-11 igs-l2 igs-mt-5">
                      <nobr>
                        <select v-model="r1mpa.m990">
                          <option value="">Sin Determinar</option>
                          <option value="1">Previsión Manual</option>
                          <option value="2">Pulmón-Picking</option>
                          <option value="3">..Uso Futuro..</option>
                          <option value="4">..Uso Futuro..</option>
                          <option value="5">..Uso Futuro..</option>
                          <option value="6">..Uso Futuro..</option>
                          <option value="7">..Uso Futuro..</option>
                          <option value="8">Limpieza</option>
                          <option value="9">Reparaciones</option>
                        </select>
                        &nbsp;
                        <input class="igs-l2" type="text" v-model="r1mpa.m991" maxlength="13" size="14">&nbsp;
                        <input class="igs-l2" type="text" v-model="r1mpa.m911" maxlength="4" size="3">&nbsp;
                        <span class="fa fa-floppy-disk" @click="lp98ProgramarReubicacion(-1)"></span>
                      </nobr>
                    </div>
                  </div>
                  <div class="row igs-l2 igs-pad-0 igs-mt-5" v-if="r1mpa.m011 != '6'">
                    <div class="col-md-5 igs-color2 igs-center">{{ lf91FechaHoraGrabacionReubicacion(r1mpa.m903, r1mpa.m904) }}</div>
                    <div class="col-md-7 igs-color2 igs-center">{{ lf91OperarioGrabacionReubicacion(r1mpa.m901, r1mpa.m902) }}</div>
                  </div>
                </div>
                <div :class="lf91ClaseCuerpo" style="overflow-y:scroll;">
                    <!-- Artículo UBICADO en este momento -->
                    <h4 class="igs-color9 igs-bold igs-center" v-show="((r1mpa.m022.length < 1 && r1mpa.m033 < 9999) || (r1mpa.m022 == $store.state.strUbicacionVaciaCodigo)) && (r1mpa.m011 != '1') && (r1mpa.m011 != '6')"><br/>Hueco VACÍO</h4>
                    <h4 class="igs-color2 igs-bold igs-center" v-show="(r1mpa.m011 == '1')">Silo MonoProducto</h4>
                    <h4 class="igs-color8 igs-bold igs-center" v-show="(r1mpa.m011 == '6')">Silo MultiProducto</h4>
                    <div class="row" v-show="r1mpa.m022.length > 0 && r1mpa.m022 != $store.state.strUbicacionVaciaCodigo">
                      <div class="col-md-9 igs-color7 igs-bold igs-h3">{{  r1mpa.m022 }} - {{ r1mpa.a002 }}</div>
                      <div class="col-md-3 igs-color7 igs-bold igs-h3">{{ lf91SumaCantidades }} {{ r1mpa.a917 }} <span class="igs-l2">{{ lf91PorcentajeOcupacionHueco }}%</span></div>
                    </div>

                    <!-- Parciales UBICADOS en este momento -->
                    <div v-show="laRegistrosMPA.length > 0">
                      <br/>
                      <div class="igs-color1 igs-bold igs-center">Cantidad Almacenada</div>
                      <table class="table table-striped table-bordered igs-fs-12" style="width:98%" >
                          <thead class="igs-center">
                              <tr>
                                  <th v-if="(r1mpa.m011 == '1' || r1mpa.m011 == '6')">Artículo</th>
                                  <th>Lote</th>
                                  <th>Caducidad</th>
                                  <th>Cantidad</th>
                                  <th>Unidades</th>
                                  <th>Última Entrada</th>
                                  <th>Acciones</th>
                              </tr>
                          </thead>
                          <tbody class="igs-center">
                              <tr v-for="(mpa,index) in laRegistrosMPA" :key="mpa.mind">
                                  <td v-if="(r1mpa.m011 == '1' || r1mpa.m011 == '6')" class="igs-left">
                                    {{ mpa.m022 }} - {{ mpa.a002 }}
                                    <div class="row">
                                      <div class="col-md-4">
                                        <barcode v-if="mpa.a051.length > 7" :value="mpa.a051" height="16" width="1" marginTop=1 marginBottom=1 displayValue="true" fontSize="9">{{mpa.a051}}</barcode>
                                      </div>
                                      <div class="col-md-8">
                                        <div class="row" v-if="r1mpa.m011 == '6'">
                                          <div class="col-md-1 igs-mt-5"><span class="fa fa-route"></span></div>
                                          <div class="col-md-11 igs-l2 igs-mt-5">
                                            <nobr>
                                              <select v-model="mpa.m990">
                                                <option value="">Sin Determinar</option>
                                                <option value="1">Previsión Manual</option>
                                                <option value="2">Pulmón-Picking</option>
                                                <option value="3">..Uso Futuro..</option>
                                                <option value="4">..Uso Futuro..</option>
                                                <option value="5">..Uso Futuro..</option>
                                                <option value="6">..Uso Futuro..</option>
                                                <option value="7">..Uso Futuro..</option>
                                                <option value="8">Limpieza</option>
                                                <option value="9">Reparaciones</option>
                                              </select>
                                              &nbsp;
                                              <input class="igs-l2" type="text" v-model="mpa.m991" maxlength="13" size="14">&nbsp;
                                              <input class="igs-l2" type="text" v-model="mpa.m911" maxlength="5" size="4">&nbsp;
                                              <span class="fa fa-floppy-disk" @click="lp98ProgramarReubicacion(index)"></span>
                                            </nobr>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span v-show="!mpa.msw1">{{ mpa.m025 }}</span>
                                    <span v-show="mpa.msw1"><input type="text" v-model="mpa.m025" size="11"></span>
                                  </td>
                                  <td>
                                    <span v-show="!mpa.msw1 && (mpa.m026 != '00000000')">{{ f70AMD2fDMA(mpa.m026) }}</span>
                                    <span v-show="mpa.msw1"><input type="text" v-model="mpa.m026" size="9"></span>
                                  </td>
                                  <td>
                                    <span v-show="!mpa.msw1">{{ mpa.m023 }}</span>
                                    <span v-show="mpa.msw1"><input type="text" v-model="mpa.m023" size="5"></span>
                                  </td>
                                  <td>
                                    <span v-show="!mpa.msw1">{{ mpa.m024 }}</span>
                                    <span v-show="mpa.msw1"><input type="text" v-model="mpa.m024" size="4"></span>
                                  </td>
                                  <td>
                                    <span v-show="!mpa.msw1">{{ f70AMD2fDMA(mpa.m027) }}</span>
                                    <span v-show="mpa.msw1"><input type="text" v-model="mpa.m027" size="9"></span>
                                  </td>
                                  <td>
                                    <!-- Modo Visualización -->
                                    <span v-show="!mpa.msw1" class="fa fa-edit" aria-hidden="true" @click="lp60ActivarEdicionMPA(index)">&nbsp;&nbsp;&nbsp;</span>
                                    <!-- Modo Edición -->
                                    <span v-show="mpa.msw1"  class="fa fa-save" aria-hidden="true" @click="lp60GrabarCambiosMPA(index)">&nbsp;&nbsp;&nbsp;</span>
                                    <span v-show="mpa.msw1"  class="fa fa-arrow-rotate-left" aria-hidden="true" @click="lp60DesactivarEdicionMPA(index)">&nbsp;&nbsp;&nbsp;</span>
                                  </td>

                              </tr>
                          </tbody>
                      </table>
                    </div>

                    <!-- Parciales Pendientes de Confirmar -->
                    <div v-show="laRegistrosDMA.length > 0">
                      <div class="igs-color2 igs-bold igs-center">Movimientos Pendientes de Cierre</div>
                      <table class="table table-striped table-bordered igs-fs-12" style="width:98%" >
                          <thead class="igs-center">
                              <tr>
                                  <th>Artículo</th>
                                  <th>Lote</th>
                                  <th>Caducidad</th>
                                  <th>Cantidad</th>
                                  <th>Unidades</th>
                                  <th>Operario</th>
                                  <th>Fecha/Hora</th>
                                  <th>Acciones</th>
                              </tr>
                          </thead>
                          <tbody class="igs-center">
                              <tr v-for="(dma, index) in laRegistrosDMA" :key="dma.dkey">
                                  <td>
                                    <span v-show="!dma.dsw1">{{ dma.d021 }} - {{ dma.a002 }}</span>
                                    <span v-show="dma.dsw1"><input type="text" v-model="dma.d021" size="7"></span>
                                  </td>
                                  <td>
                                    <span v-show="!dma.dsw1">{{ dma.d022 }}</span>
                                    <span v-show="dma.dsw1"><input type="text" v-model="dma.d022" size="11"></span>
                                  </td>
                                  <td>
                                    <span v-show="!dma.dsw1">{{ f70AMD2fDMA(dma.d023) }}</span>
                                    <span v-show="dma.dsw1"><input type="text" v-model="dma.d023" size="9"></span>
                                  </td>
                                  <td>
                                    <span v-show="!dma.dsw1">{{ dma.d060 }}</span>
                                    <span v-show="dma.dsw1"><input type="text" v-model="dma.d060" size="4"></span>
                                  </td>
                                  <td>
                                    <span v-show="!dma.dsw1">{{ dma.d061 }}</span>
                                    <span v-show="dma.dsw1"><input type="text" v-model="dma.d061" size="3"></span>
                                  </td>
                                  <td class="igs-l1">
                                    {{ dma.d082 }}
                                  </td>
                                  <td class="igs-l1">
                                    {{ f70AMD2sDMA(dma.d083) }} {{ f70HMS2fHMS(dma.d084) }}
                                    <span class="igs-l1 igs-color8" v-if="dma.d009 == '1'"><br/>{{ f93DescripcionTipoMovimientoDMA(dma.d011,dma.d009,true) }}<br/><span v-html="f93DescripcionReferenciaTextoDMA(dma.d011,dma.d014,dma.d013,dma.d077)"></span></span>
                                    <span class="igs-l1 igs-color9" v-if="dma.d009 == '4'"><br/>{{ f93DescripcionTipoMovimientoDMA(dma.d011,dma.d009,true) }}<br/><span v-html="f93DescripcionReferenciaTextoDMA(dma.d011,dma.d014,dma.d013,dma.d077)"></span></span>
                                    <span class="igs-l1 igs-color9" v-if="dma.d009 == '5'"><br/>{{ f93DescripcionTipoMovimientoDMA(dma.d011,dma.d009,true) }}<br/><span v-html="f93DescripcionReferenciaTextoDMA(dma.d011,dma.d014,dma.d013,dma.d077)"></span></span>
                                    <span class="igs-l1 igs-color7" v-if="dma.d009 > '5'"><br/>{{ f93DescripcionTipoMovimientoDMA(dma.d011,dma.d009,true) }}<br/><span v-html="f93DescripcionReferenciaTextoDMA(dma.d011,dma.d014,dma.d013,dma.d077)"></span></span>                                    
                                  </td>
                                  <td>
                                    <!-- Modo Visualización -->
                                    <span v-show="!dma.dsw1" class="fa fa-edit" aria-hidden="true" @click="lp60ActivarEdicionDMA(index)">&nbsp;&nbsp;&nbsp;</span>
                                    <span v-show="!dma.dsw1" class="fa fa-trash" aria-hidden="true" @click="lp60BorrarDMA(index)">&nbsp;&nbsp;&nbsp;</span>
                                    <!-- Modo Edición -->
                                    <span v-show="dma.dsw1"  class="fa fa-save" aria-hidden="true" @click="lp60GrabarCambiosDMA(index)">&nbsp;&nbsp;&nbsp;</span>
                                    <span v-show="dma.dsw1"  class="fa fa-arrow-rotate-left" aria-hidden="true" @click="lp60DesactivarEdicionDMA(index)">&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>

                    <!-- Movimientos de Carga Pendientes -->
                    <div v-show="laRegistrosLOT.length > 0">
                      <div class="igs-color7 igs-bold igs-center">Movimientos de Carga Pendientes</div>
                      <table class="table table-striped table-bordered igs-fs-12" style="width:98%" >
                          <thead class="igs-center">
                              <tr>
                                  <th>Artículo</th>
                                  <th>Lote</th>
                                  <th>Caducidad</th>
                                  <th>Cantidad</th>
                                  <th>Unidades</th>
                                  <th>Operario</th>
                                  <th>Fecha/Hora</th>
                                  <th>Acciones</th>
                              </tr>
                          </thead>
                          <tbody class="igs-center">
                              <tr v-for="(lot) in laRegistrosLOT" :key="lot.lkey">
                                  <td>
                                    <span v-show="!lot.lsw1">{{ lot.l021 }} - {{ lot.a002 }}</span>
                                    <span v-show="lot.lsw1"><input type="text" v-model="lot.l021" size="7"></span>
                                  </td>
                                  <td>
                                    <span v-show="!lot.lsw1">{{ lot.l022 }}</span>
                                    <span v-show="lot.lsw1"><input type="text" v-model="lot.l022" size="11"></span>
                                  </td>
                                  <td>
                                    <span v-show="!lot.lsw1">{{ f70AMD2fDMA(lot.l023) }}</span>
                                    <span v-show="lot.lsw1"><input type="text" v-model="lot.l023" size="9"></span>
                                  </td>
                                  <td>
                                    <span v-show="!lot.lsw1">{{ lot.l060 }}</span>
                                    <span v-show="lot.lsw1"><input type="text" v-model="lot.l060" size="4"></span>
                                  </td>
                                  <td>
                                    <span v-show="!lot.lsw1">{{ lot.l061 }}</span>
                                    <span v-show="lot.lsw1"><input type="text" v-model="lot.l061" size="3"></span>
                                  </td>
                                  <td class="igs-l1">
                                    {{ lot.l082 }}
                                  </td>
                                  <td class="igs-l1">
                                    {{ f70AMD2sDMA(lot.l083) }} {{ f70HMS2fHMS(lot.l084) }}
                                    <span class="igs-l1 igs-color8" v-if="lot.l009 == '1'"><br/>{{ f93DescripcionTipoMovimientoDMA(lot.l011,lot.l009,true) }}<br/>{{ f93DescripcionReferenciaTextoDMA(lot.l011,lot.l014,lot.l013,'') }}</span>
                                    <span class="igs-l1 igs-color8" v-if="lot.l009 == '2'"><br/>{{ f93DescripcionTipoMovimientoDMA(lot.l011,lot.l009,true) }}<br/>{{ f93DescripcionReferenciaTextoDMA(lot.l011,lot.l014,lot.l013,'') }}</span>
                                    <span class="igs-l1 igs-color9" v-if="lot.l009 == '4'"><br/>{{ f93DescripcionTipoMovimientoDMA(lot.l011,lot.l009,true) }}<br/>{{ f93DescripcionReferenciaTextoDMA(lot.l011,lot.l014,lot.l013,'') }}</span>
                                    <span class="igs-l1 igs-color9" v-if="lot.l009 == '5'"><br/>{{ f93DescripcionTipoMovimientoDMA(lot.l011,lot.l009,true) }}<br/>{{ f93DescripcionReferenciaTextoDMA(lot.l011,lot.l014,lot.l013,'') }}</span>
                                    <span class="igs-l1 igs-color7" v-if="lot.l009 > '5'"><br/>{{ f93DescripcionTipoMovimientoDMA(lot.l011,lot.l009,true) }}<br/>{{ f93DescripcionReferenciaTextoDMA(lot.l011,lot.l014,lot.l013,'') }}</span>                                    
                                  </td>
                                  <td>
                                    <!--
                                      < !-- Modo Visualización -- >
                                      <span v-show="!lot.lsw1" class="fa fa-edit" aria-hidden="true" @click="lp60ActivarEdicionLOT(index)">&nbsp;&nbsp;&nbsp;</span>
                                      <span v-show="!lot.lsw1" class="fa fa-trash" aria-hidden="true" @click="lp60BorrarLOT(index)">&nbsp;&nbsp;&nbsp;</span>
                                      < !-- Modo Edición -- >
                                      <span v-show="lot.lsw1"  class="fa fa-save" aria-hidden="true" @click="lp60GrabarCambiosLOT(index)">&nbsp;&nbsp;&nbsp;</span>
                                      <span v-show="lot.lsw1"  class="fa fa-arrow-rotate-left" aria-hidden="true" @click="lp60DesactivarEdicionLOT(index)">&nbsp;&nbsp;&nbsp;</span>
                                    -->
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>

                </div>
            </div>
          </div>

          <!-- Pie -->
          <div class="igs-center">
              <b-button variant="outline-danger" pill @click="lp10Close()">Cerrar</b-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import igs_mixin from '@/igs-mixins/igs_mixin';
  import axios     from 'axios';
  import VueBarcode from 'vue-barcode';

  export default {
    mixins: [ igs_mixin ],
    props:  {
        ubicacion:    String,
        fechaInicial: String,
        visible:      Boolean
    },    
    components : { 'barcode': VueBarcode },

    //----------------------------
    //-- Datos Locales
    //----------------------------
    data() {
        return {
            //------------------------------------------------------------------------
            //-- Variables Locales
            //------------------------------------------------------------------------
            lbResultadosCompletos : false,                        //-- Contamos con todos los valores necesarios (S/N)
            lbVentanaVisible : false,                             //-- Ventana Visiblae (S/N)
                                                                  //-- (Para usar con el (watch) y lograr la renovación del contenido al MOSTRAR la ventana)
            ltDetallesHueco : '',                                 //-- HTML con los Detalles del Hueco
            r1mpa : {},                                           //-- Registro MPA
            laRegistrosDMA : [],                                  //-- Movimientos Provisionales PENDIENTES DE CONFIRMACION
            laRegistrosLOT : [],                                  //-- Movimientos Provisionales PENDIENTES DE CARGA
            laRegistrosMPA : [],                                  //-- Parciales Lote + Caducidad ALMACENADOS ACTUALMENTE

            lbEditarTipoUbicacion : false                         //-- Mostrar Controles para Edición del Tipo de Ubicación
        }
    },

    //------------------------------------------------
    //-- Examinar Cambios de Valor
    //------------------------------------------------
    watch: {
        //-- Ventana Abierta/Cerrada
        visible(newVal, oldVal){
            //-- LOG
            this.p99Log(5,1,oldVal);

            //-- Actualizar Valor
            this.lbVentanaVisible = newVal;

            //-- Al mostrar la ventana : Refrescar Contenido
            if (this.lbVentanaVisible) { 
              //-- Inicializaciones
              this.lbEditarTipoUbicacion = false;
      
              //-- Mostrar Detalles 
              this.lp92DetallesHueco(); 
            }
        }   
    },

    //----------------------------
    //-- Datos Calculados
    //----------------------------
    computed: {

      //......................................
      //-- Clase para la Imagen del Artículo
      //......................................
      lf91ClaseImagen() {
        if ((this.r1mpa.m022 == '') || (this.r1mpa.m033 >= 9999)) {
          return "col-md-0 igs-center";
        } else {
          return "col-md-3 igs-center";
        }
      },

      //......................................
      //-- Clase para el Cuerpo de la Ventana
      //......................................
      lf91ClaseCuerpo() {
        if ((this.r1mpa.m022 == '') || (this.r1mpa.m033 >= 9999)) {
          return "col-md-12 igs-left";
        } else {
          return "col-md-9 igs-left";
        }
      },

      //...................................
      //-- Suma Cantidades Almacenadas
      //...................................
      lf91SumaCantidades() {

        //-- Inicializar
        let suma = 0;
        let i    = 0;

        //-- Acumular
        while (i < this.laRegistrosMPA.length) {
          suma  += this.laRegistrosMPA[i].m023;
          i     += 1;
        }

        //-- Devolver resultado acumulado
        return  suma;
      },

      //.......................................
      //-- Suma Cantidades convertida a Palés
      //.......................................
      lf91CantidadConvertidaEnPales() {
        return this.f91Importe(this.lf91SumaCantidades / this.r1mpa.mbxh);
      },


      //.......................................
      //-- Porcentaje de Ocupación del Hueco
      //.......................................
      lf91PorcentajeOcupacionHueco() {
        return this.f91Porcentaje(this.lf91SumaCantidades / this.r1mpa.mbxh * 100);
      }

    },

    //------------------------------------------------
    //-- Métodos Locales
    //------------------------------------------------
    methods: {
        //------------------------------------
        //-- Cerrar Ventana
        //------------------------------------
        lp10Close() {
            this.$emit('close');
        },

        //--------------------------------------------------------------------------------
        //-- Pasar a Modo Edición del Registro DMA
        //--------------------------------------------------------------------------------
        lp60ActivarEdicionDMA(index) {
          this.p99Log(5,1,' -- lp60ActivarEdicionDMA -- ('+index+')');
          this.laRegistrosDMA[index].dsw1 = true;
        },

        //--------------------------------------------------------------------------------
        //-- Pasar a Modo Visualización del Registro DMA
        //--------------------------------------------------------------------------------
        lp60DesactivarEdicionDMA(index) {
          this.p99Log(5,1,' -- lp60DesactivarEdicionDMA -- ('+index+')');
          this.laRegistrosDMA[index].dsw1 = false;
        },

        //--------------------------------------------------------------------------------
        //-- Grabar Cambios en Registro DMA
        //--------------------------------------------------------------------------------
        lp60GrabarCambiosDMA(index) {

          //-- Solicitar DETALLES de un determinado HUECO
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=234&m=902&x=" + 
                    encodeURIComponent(this.laRegistrosDMA[index].dkey+'^'+this.laRegistrosDMA[index].d021+'^'+this.laRegistrosDMA[index].d022+'^'+this.laRegistrosDMA[index].d023+'^'+this.laRegistrosDMA[index].d060+'^'+this.laRegistrosDMA[index].d061+'^'+'000'+'^'+this.$store.state.strMailUsuario);
          this.p99Log(5,1,' (lp60GrabarCambiosDMA) URL = '+URL);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (234902) --');
              this.p99Log(1,2,datos);

              if (flg == global.lnkGetDataOk)  {
                  this.p99Log(1,2,' ¡¡ EXITO !!');

                  //-- Refrescar Resultados
                  this.lp92DetallesHueco();
              } else {                  
                this.p99Log(1,1,' #### ERROR (234902) ####');
                this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1);
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.p99AnuncioIA1('2','7','ERROR', "Error al tratar de ACTUALIZAR el Movimiento seleccionado",-1);
          });

        },

        //--------------------------------------------------------------------------------
        //-- Borrar Registro DMA
        //--------------------------------------------------------------------------------
        lp60BorrarDMA(index) {

          //-- Solicitar DETALLES de un determinado HUECO
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=234&m=903&x=" + encodeURIComponent(this.laRegistrosDMA[index].dkey);
          this.p99Log(5,1,' (lp60BorrarDMA) URL = '+URL);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (234903) --');
              this.p99Log(1,2,datos);

              if (flg == global.lnkGetDataOk)  {
                  this.p99Log(1,2,' ¡¡ EXITO !!');

                  //-- Refrescar Resultados
                  this.lp92DetallesHueco();
              } else {                  
                this.p99Log(1,1,' #### ERROR (234903) ####');
                this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1);
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.p99AnuncioIA1('2','7','ERROR', "Error al tratar de BORRAR el Movimiento seleccionado",-1);
          });

        },


        //--------------------------------------------------------------------------------
        //-- Pasar a Modo Edición del Registro MPA
        //--------------------------------------------------------------------------------
        lp60ActivarEdicionMPA(index) {
          this.p99Log(5,1,' -- lp60ActivarEdicionMPA -- ('+index+')');
          this.laRegistrosMPA[index].msw1 = true;
        },

        //--------------------------------------------------------------------------------
        //-- Pasar a Modo Visualización del Registro MPA
        //--------------------------------------------------------------------------------
        lp60DesactivarEdicionMPA(index) {
          this.p99Log(5,1,' -- lp60DesactivarEdicionMPA -- ('+index+')');
          this.laRegistrosMPA[index].msw1 = false;
        },

        //--------------------------------------------------------------------------------
        //-- Grabar Cambios en Registro MPA
        //--------------------------------------------------------------------------------
        lp60GrabarCambiosMPA(index) {

          //-- Solicitar DETALLES de un determinado HUECO
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=902&x=" + 
                    encodeURIComponent(this.ubicacion+'^'+this.laRegistrosMPA[index].mind+'^'+this.laRegistrosMPA[index].m023+'^'+this.laRegistrosMPA[index].m024+'^'+this.laRegistrosMPA[index].m025+'^'+this.laRegistrosMPA[index].m026+'^'+this.laRegistrosMPA[index].m027+'^'+'000'+'^'+this.$store.state.strMailUsuario);
          this.p99Log(5,1,' (lp60GrabarCambiosMPA) URL = '+URL);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (234902) --');
              this.p99Log(1,2,datos);

              if (flg == global.lnkGetDataOk)  {
                  this.p99Log(1,2,' ¡¡ EXITO !!');

                  //-- Refrescar Resultados
                  this.lp92DetallesHueco();
              } else {                  
                this.p99Log(1,1,' #### ERROR (200902) ####');
                this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1);
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.p99AnuncioIA1('2','7','ERROR', "Error al tratar de ACTUALIZAR la Ubicación seleccionada",-1);
          });

        },

        //--------------------------------------------------------------------------------
        //-- Conmutar el estado de la Edición del Tipo de Ubicación
        //--------------------------------------------------------------------------------
        lp60ConmutarEdicionTipoUbicacion() {
          this.lbEditarTipoUbicacion = !this.lbEditarTipoUbicacion;
        },

        //--------------------------------------------------------------------------------
        //-- Grabar Cambio del Tipo de Ubicacion en Registro MPA
        //--------------------------------------------------------------------------------
        lp60GrabarCambioTipoUbicacion() {

          //-- Solicitar DETALLES de un determinado HUECO
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=912&x=" + 
                    encodeURIComponent(this.ubicacion+'^'+this.r1mpa.m011+'^'+'000'+'^'+this.$store.state.strMailUsuario);
          this.p99Log(5,1,' (lp60GrabarCambioTipoUbicacion) URL = '+URL);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (200912) --');
              this.p99Log(1,2,datos);

              if (flg == global.lnkGetDataOk)  {
                  this.p99Log(1,2,' ¡¡ EXITO !!');

                  //-- Refrescar Resultados
                  this.lp92DetallesHueco();
              } else {                  
                this.p99Log(1,1,' #### ERROR (200912) ####');
                this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1);
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.p99AnuncioIA1('2','7','ERROR', "Error al tratar de ACTUALIZAR el TIPO DE UBICACIÓN",-1);
          });

        },


        //--------------------------------------------------------------------------------
        //-- URL de la Imagen 500 x 500
        //--------------------------------------------------------------------------------
        lf91ImagenArticulo500(codigo) {
            //-- Retornar la URL a utilizar
            var URL = global.lnkServerImgArticulosURL+"u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&i="+codigo+"&t=500&x=C";

            //-- Informar Tabla y Método solicitado (y la URL completa)
            this.p99Log(1,1,"URL Imagen 500 () ==> "+URL);

            //-- Devolver el resultado obtenido
            return URL;
        },

        //----------------------------------------------------------------------
        //-- Al conmutar de valor la check-box (Dibujar)
        //----------------------------------------------------------------------
        lp92DetallesHueco() {

            //-- Inicializar contenido
            this.lbResultadosCompletos = false;
            this.ltDetallesHueco       = "<br/><br/>Esperando la respuesta del servidor ....<br/><br/><br/>";

            //-- Solicitar DETALLES de un determinado HUECO
            var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=101&x=" + encodeURIComponent(this.ubicacion) + "&y="  + encodeURIComponent(this.fechaInicial);
            this.p99Log(1,1,' (lf92DetallesHueco) URL = '+URL);

            //-- Contactar con el Servidor del Distribuidor
            axios.get(URL, {responseEncoding: 'utf-8'})                         
            .then(respuesta => {

                //-- Extraer la respuesta recibida
                var datos = respuesta.data;

                //-- Determinar si ha habido EXITO ó ERROR
                var flg = global.lnkGetDataError;
                if (datos.flg) {  flg = datos.flg; }

                //-- Actualizar Resultados
                this.p99Log(1,1,'-- Respuesta (200101) --');
                this.p99Log(1,2,datos);

                if (flg == global.lnkGetDataOk)  {
                    this.p99Log(1,2,' ¡¡ EXITO !!');
                    this.r1mpa          = datos.t200m101[0];

                    this.laRegistrosDMA = datos.t200m101[0].mdma.sort(function(a, b){
                                                  var nameA=a.d021+'_'+a.md023+'_'+a.d022, nameB=b.d021+'_'+b.d023+'_'+b.d022;
                                                  if (nameA < nameB) { return -1; }
                                                  if (nameA > nameB) { return  1; }
                                                  return 0; 
                                              });

                    this.laRegistrosLOT = datos.t200m101[0].mlot.sort(function(a, b){
                                                  var nameA=a.l021+'_'+a.ld023+'_'+a.l022, nameB=b.l021+'_'+b.l023+'_'+b.l022;
                                                  if (nameA < nameB) { return -1; }
                                                  if (nameA > nameB) { return  1; }
                                                  return 0; 
                                              });

                    this.laRegistrosMPA = datos.t200m101[0].mmpa.sort(function(a, b){
                                                  var nameA=a.m022+'_'+a.m026+'_'+a.m025, nameB=b.m022+'_'+b.m026+'_'+b.m025;
                                                  if (nameA < nameB) { return -1; }
                                                  if (nameA > nameB) { return  1; }
                                                  return 0; 
                                              });

                    this.p99Log(1,1,' -- Registros DMA : '+this.laRegistrosDMA.length);

                    //-- Determinar el MENSAJE a mostrar
                    if (((this.r1mpa.m022.trim() == '') && (this.r1mpa.mdma.length == 0)) || (this.r1mpa.m022 == this.$store.state.lnkUbicacionVaciaCodigo)) {
                        this.ltDetallesHueco = "Este HUECO se encuentra VACÍO";              
                    } else {
                        this.ltDetallesHueco = "Este HUECO se encuentra OCUPADO";              
                    }

                    //-- Ya podemos mostrar el resultado
                    this.lbResultadosCompletos = true;
                }

            })
            .catch(error => {
                //-- Mostrar el Mensaje de Error oportuno
                this.p99Log(1,1,'** ERROR **');
                this.p99Log(1,1,error);

                //-- Mensaje al Operador
                this.ltDetallesHueco = "<br/><br/><div style='color:red'>Error al tratar de acceder al Hueco <b>"+this.ubicacion+"</b></div><br/><br/>";
            });

        },

        //----------------------------------------------------------------
        //-- Maquetar Fecha/Hora en la que fue programada la Reubicación
        //----------------------------------------------------------------
        lf91FechaHoraGrabacionReubicacion(fecha, hora) {
          //-- Valor "vacio" : Nada que mostrar
          if ((fecha == '') || (fecha == '00000000')) { return ''; }

          //-- Retornar fecha y Hora        
          return this.f70AMD2fDMA(fecha) + ' ' + this.f70HMS2fHMS(hora);
        },

        //----------------------------------------------------------------------------
        //-- Maquetar Dispositivo/Operario por el que fue programada la Reubicación
        //----------------------------------------------------------------------------
        lf91OperarioGrabacionReubicacion(dispositivo, operario) {
          //-- Valor "vacio" : Nada que mostrar
          if ((dispositivo == '000') && (operario == '')) { return ''; }

          //-- Retornar Dispositivo y Operario        
          return '('+dispositivo+') '+operario;
        },

        //--------------------------------------------------------------------------------
        //-- Programar Reubicación del contenido de la presente Ubicación
        //--------------------------------------------------------------------------------
        lp98ProgramarReubicacion(indice) {
          //console.log('Programando Reubicación ('+indice+')');

          //-- Variables Locales
          let ubicacionInicial = '';
          let ubicacionFinal = '';
          let motivo = '';
          let cantidad = '0';
          let unidades = '0';

          if (indice == -1) {
            ubicacionInicial    = this.r1mpa.m001;
            ubicacionFinal      = this.r1mpa.m991;
            motivo              = this.r1mpa.m990;
            cantidad            = this.r1mpa.m911;
            unidades            = '0';
          } else {
            ubicacionInicial    = this.laRegistrosMPA[indice].m001;
            ubicacionFinal      = this.laRegistrosMPA[indice].m991;
            motivo              = this.laRegistrosMPA[indice].m990;
            cantidad            = this.laRegistrosMPA[indice].m911;
            unidades            = '0';
          }

          //==================================================
          //-- Comprobaciones Previas
          //==================================================

          //-- Comprobar MOTIVO
          if ((motivo < '0') || (motivo > '9')) {
            this.p99AnuncioIA1('3','7','Aviso', 'Debes seleccionar un MOTIVO para la Reubicación.','',null);
            return;
          }

          //-- Comprobar NUEVA UBICACIÓN
          if (ubicacionFinal == ubicacionInicial) {
            this.p99AnuncioIA1('3','7','Aviso', 'La Ubicación de DESTINO debe ser DIFERENTE a la de ORIGEN.','',null);
            return;
          }

          if (ubicacionFinal.length != 13) {
            this.p99AnuncioIA1('3','7','Aviso', 'La Ubicación de DESTINO es INCORRECTA.','',null);
            return;
          }

          //==================================================
          //-- Actualizar en el Servidor Remoto
          //==================================================

          //-- Extraer el Usuario Actual
          let usuario = this.$store.state.strMailUsuario;

          //-- En caso de E-Mail nos quedamos con el PRIMER FRAGMENTO
          let fragmentos = usuario.split("@");
          if (fragmentos.length > 1) { usuario = fragmentos[0]; }

          //-- Solicitar DETALLES de un determinado HUECO
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=191&x=" + encodeURIComponent(ubicacionInicial+'^'+ubicacionFinal+'^'+motivo+'^'+cantidad+'^'+unidades+'^'+'000'+'^'+usuario);
          this.p99Log(1,1,' (lp98ProgramarReubicacion) URL = '+URL);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (200191) --');
              this.p99Log(1,2,datos);

              if (flg == global.lnkGetDataOk)  {
                  //-- Informar Éxito
                  this.p99Log(1,2,' ¡¡ EXITO !!');
                  this.p99AnuncioIA1('1','7','¡ Éxito !', 'Reubicación registrada CON ÉXITO.',3);

                  //-- Refrescar información
                  this.lp92DetallesHueco();
              } else {
                this.p99Log(1,1,' #### ERROR (200191) ####');
                if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.ltDetallesHueco = "<br/><br/><div style='color:red'>Error al tratar de registrar la Reubicación del contenido de la Ubicación <b>"+this.ubicacion+"</b></div><br/><br/>";
          });

        }

    },

    //----------------------------
    //-- Al cargar esta página
    //----------------------------
    mounted() {
    
      //-- Lectura de los atributos de la UBICACIÓN recibida
      this.lp92DetallesHueco();

    }

  };
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 80vw;
  max-height:80vh;
  overflow-y:scroll;
  margin: 0px auto;
  padding: 10px 10px;
  background-color: rgba(255, 255, 255, 0.96);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 0.25rem;
}
.modal-header {
  padding: 0px 0px 0px 0px;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "Roboto Mono", monospace;
}
.modal-body {
  margin: 0px 0;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
